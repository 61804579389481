@import 'breakpoints';
@import 'palette';

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

/*------------------------------------*\
    #General Styles
\*------------------------------------*/

body {
    font-family: $fontFamily;
}

hr {
    border-color: $gray-500;
}

.grey-background {
    background-color: $gray-100;
}

.bg-dark {
    background-color: #000;
}

.bg-dark-gradient {
    background: radial-gradient(farthest-side at top, #000 0, #161616 100%);
}

.hover-translate-y {
    transition: all 0.3s ease;
    &:hover {
        transform: translateY(-8px) !important;
        transition: all 0.3s ease !important;
    }
}
.grey-scale {
    filter: grayscale(1);
}

.contrast-color {
    color: $white;
}

.brand-color {
    color: map-get($theme-colors, 'primary');
}

.cursor-pointer {
    cursor: pointer;
}

.navbar-logo {
    svg {
        width: 11rem;
        height: auto;
    }
}

.gradient-background {
    background: linear-gradient(90deg, $primary-gradient 0%, $secondary-gradient 100%);
}

.logo-image-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 3rem;
    padding: 3rem 0;
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: 0.25;
}

.row {
    margin: 0;
}

.zindex-offcanvas {
    z-index: 1000;
}
.z-index-1 {
    z-index: 1;
}

/*------------------------------------*\
    #Default Animations
\*------------------------------------*/

.bounce {
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

/*------------------------------------*\
    #Typo
\*------------------------------------*/

.lead,
h2 {
    line-height: $lead-font-line-height !important;
    letter-spacing: $lead-font-letter-spacing !important;
    font-weight: $lead-font-weight;
}

/*------------------------------------*\
    #Dark Mode
\*------------------------------------*/

.bootstrap-dark {
    background-color: $black;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $white;
    }
    .grey-background {
        background-color: $gray-900;
    }
    .m-card-item {
        .border {
            background-color: $gray-900 !important;
        }
    }
    .m-card-over-image {
        .card {
            background-color: $gray-900 !important;
        }
    }
}

/*------------------------------------*\
    #Text Ellipsis
\*------------------------------------*/

.ellipsis {
    display: -webkit-box;
    max-width: 30rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 4.1rem;
}

/*------------------------------------*\
    #Buttons
\*------------------------------------*/

.btn {
    text-transform: $btn-font-transform;
    transition: 0.3s all;
}
a {
    text-transform: capitalize;
}

.btn-outline-primary {
    border-width: 2px;
    color: $white;
    border-color: $white;
    &:hover {
        background-color: $white;
        color: map-get($theme-colors, 'primary');
    }
}
.btn-outline-dark {
    border-width: 2px;
}
.btn-outline-light {
    color: $white;
    border-width: 2px;
    border-color: $white;
    &:hover {
        background-color: transparent !important;
    }
}

/*------------------------------------*\
    #Video JS
\*------------------------------------*/

.video-js {
    height: 100% !important;
}

/*------------------------------------*\
    #Animations
\*------------------------------------*/

.animatedDropdown {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

/*------------------------------------*\
    #Navbar & Footer Links
\*------------------------------------*/

.o-navbar {
    transition: all 0.3s ease-in;
    .navbar-nav {
        .show > .nav-link {
            color: $nav-link-color;
        }
    }
    .nav-link {
        color: $nav-link-color;
        text-transform: $nav-link-font-transform;
        border: 0;
        margin-right: 1.5rem;
        font-weight: $nav-link-font-weight;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        overflow: hidden;
        background: transparent;
        @media (min-width: $tablet) {
            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                left: 0;
                right: 0;
                top: 0px;
                background: map-get($theme-colors, 'primary');
                height: 4px;
                -webkit-transform: translateY(-4px);
                transform: translateY(-4px);
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover::before {
                content: '';
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                top: 0px;
                background: map-get($theme-colors, 'primary');
                height: 4px;
                transform: none;
            }
        }
    }

    &.navbar-on-scroll {
        border-bottom: 1px solid rgba(67, 72, 76, 0.1);
        background-color: $navbar-bg-color-on-scroll;
        padding: $navbar-collapsed-padding;
        transition: all 0.3s ease-in;
        .nav-link {
            color: $navbar-link-color-on-scroll;
        }
        .navbar-nav {
            .show > .nav-link {
                color: $navbar-link-color-on-scroll;
            }
        }
        .btn {
            background-color: #e73e25;
        }
        .active {
            color: $navbar-link-color-on-scroll !important;
        }
    }

    button {
        font-size: $nav-link-font-size;
        text-transform: $nav-link-font-transform;
    }
    .dropdown-toggle {
        &::after {
            display: none;
        }
    }
    .active {
        color: $nav-link-color !important;
        @media (min-width: $tablet) {
            &::before {
                content: '';
                right: 0;
                top: 0;
                background: #d5333e;
                height: 4px;
                left: 0;
                position: absolute;
                transform: translateY(0);
            }
        }
    }
}

// Navbar Mobile

.o-navbar {
    @media (max-width: $tablet) {
        padding: 1rem;
        &.navbar-on-scroll {
            padding: 1rem;
        }
    }
    .menu-container {
        @media (max-width: $tablet) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            i {
                font-size: 1.5rem !important;
                color: $nav-link-color !important;
            }
        }
    }
    &.navbar-on-scroll {
        @media (max-width: $tablet) {
            background-color: #000;
        }
    }
    .modal-header {
        @media (max-width: $tablet) {
            padding: 0;
        }
    }
}

// Footer

.o-footer {
    button{
        background: transparent;
        border: 0;
    }
}

.footer-link-color {
    color: $footer-font-color;
}

.color-gray {
    color: $gray-600;
}

/*------------------------------------*\
    #Responsive
\*------------------------------------*/

@media (max-width: $tablet) {
    .hide-mobile {
        display: none !important;
    }
    .d-grid {
        display: flex;
        flex-direction: column;
    }
    .w-sm-50 {
        width: 50%;
    }
    .d-xs-flex {
        display: flex !important;
    }
}
@media (max-width: $mobile) {
    .pl-xs-0 {
        padding-left: 0 !important;
    }
}

@media (min-width: $tablet) {
    .hide-desktop {
        display: none !important;
    }
    .w-md-50 {
        width: 50%;
    }
}

/*------------------------------------*\
    #Forms
\*------------------------------------*/

.login-form-container {
    height: 100%;
    padding-top: 10rem;
    width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .forgot {
        text-align: end;
    }
    @media (max-width: $desktop) {
        width: 100%;
    }
}

.o-post-form,
.o-login-form-all {
    color: white;
    &.post-modal {
        padding: 2rem;
        @media (max-width: $mobile) {
            padding: 2rem 0;
        }
    }
    .form-container {
        margin: 0 1rem;
        border: none;
    }
    .btn-primary:disabled {
        cursor: not-allowed;
    }
    .btn-secondary:disabled {
        cursor: not-allowed;
    }
    .form-label {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        text-transform: uppercase;
        color: $black;
        font-weight: bolder;
    }
    .form-control {
        background-color: transparent;
        color: white;
        padding: 20px 10px;
        border: 0;
        border-bottom: 2px solid $gray-600;
        border-radius: 0;
        height: 45px;
        font-size: 1rem;
    }
    .file-upload {
        padding: 0.375rem 0.75rem;
    }
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .form-control:disabled {
        cursor: not-allowed;
    }
    .form-control::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray-600;
        opacity: 1;
        /* Firefox */
    }
    .form-control:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $gray-600;
    }
    .form-control::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $gray-600;
    }
}

/*------------------------------------*\
    #Modal
\*------------------------------------*/

.member-image {
    width: 8rem;
    height: 8rem;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
}
.member-wrapper {
    display: flex;
    @media (max-width: $tablet) {
        flex-direction: column;
    }
}
.fullscreen-modal .modal-dialog {
    max-width: 100%;
    margin: auto;
}

@media (max-width: $tablet) {
    .modal-dialog,
    .modal-content,
    .mobile-menu {
        height: 100%;
        margin: 0;
    }
}

.modal-content,
.modal-content-form {
    border: 0;
    border-radius: 0;
}

/*------------------------------------*\
    #Carrusel
\*------------------------------------*/

.carousel-control-prev,
.carousel-control-next {
    width: 10%;
}

.fullscreen lightbox {
    z-index: 1999;
}

/*------------------------------------*\
    #Blog
\*------------------------------------*/

.t-detail-centered {
    img {
        width: 100%;
    }
}

.m-table-item {
    h2 {
        word-break: break-word;
    }
}

.scrollContent {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;
}

.scrollContent::-webkit-scrollbar {
    display: none;
}

.spinner-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.spinner {
    border: 0.25rem solid rgba(0, 0, 0, 0.1);
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    border-left-color: #e73e25;

    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

table tr th:last-child {
    border-bottom-right-radius: 1.688rem;
    border-top-right-radius: 1.688rem;
}

table tr {
    border-radius: 1.688rem;
}

table tr th:first-child {
    border-bottom-left-radius: 1.688rem;
    border-top-left-radius: 1.688rem;
}

/*------------------------------------*\
    #Blog Detail
\*------------------------------------*/

.m-avatar {
    margin-top: 2rem !important;
    p {
        color: $gray-600 !important;
    }
}

/*------------------------------------*\
    #Job Table List
\*------------------------------------*/
.job-posting {
    .o-table-list {
        td {
            border-top: none;
            border-bottom: 2px solid $black;
        }
        .button-icon {
            color: map-get($theme-colors, 'primary');
        }
        .role-text {
            font-size: 1.875rem;
        }
        .text {
            font-size: 0.875rem;
        }
        .logo {
            .img-fluid {
                max-width: 8.25rem;
                width: unset !important;
            }
        }
        .th-header {
            line-height: 1.5rem;
            border-top: none;
            border-bottom: none;
            padding: 0 0 0 0.75rem;
            font-size: 0.875rem;
            cursor: default;
        }
        .th-header:first-child {
            padding-left: 2.375rem;
        }

        .tr-header {
            background-color: #f4f4f4;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        .filter-header {
            text-align: right;
            width: 13.75rem;
        }
        .bold {
            font-weight: bold;
        }
        .black {
            color: $black;
        }
        .inline {
            .a-icon {
                display: inline;
                margin-right: 0.3rem;
            }
        }
        .bottom-container {
            text-align: center;
            @media (max-width: $tablet) {
                padding: 0 1rem;
                text-align: left;
            }
            .link-color {
                color: $black;
                font-size: 1.25rem;
            }
        }
        .input-search {
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 5rem;
            background-color: #f4f4f4;
            height: 3.375rem;
            border: none;
            padding-left: 2.125rem;
            padding-right: 2.125rem;
            color: $gray-600;
            font-size: 1rem;
        }
        .button-filter {
            button {
                height: 3.375rem;
                background-color: $black;
                border: none;
            }
            .filter-btn-clear {
                background-color: $white !important;
                text-transform: uppercase;
                height: 1rem;
                width: 100%;
                text-align: left;
                margin-bottom: 1rem;
                color: map-get($theme-colors, 'primary');
            }
            .filter-menu {
                padding: 0.5rem 0 0 0;
                width: 16.5rem;
            }
            .filter-btn-bottom {
                border-radius: 0%;
                width: 100%;
                color: $white;
                text-align: center;
            }

            .filter-label {
                font-size: 0.875 !important;
                font-weight: bold;
                text-transform: uppercase;
                padding-left: 1.5rem;
                padding-bottom: 0.5rem;
                margin-top: 1rem;
            }
            .filter-content {
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.69);
                padding-bottom: 1rem;
            }
            .form-check {
                height: 2rem;
            }
        }

        .button-search {
            border-top-right-radius: 5rem;
            border-bottom-right-radius: 5rem;
            background-color: map-get($theme-colors, 'primary');
            width: 3.75rem;
            padding: 0.375rem 1rem;
            .a-icon i {
                font-size: 1.25rem !important;
            }
        }

        .form-control:focus,
        .btn-primary.dropdown-toggle:focus,
        .btn-primary:focus,
        .btn-primary.focus,
        .btn-primary:not(:disabled):not(.disabled):active:focus,
        .btn-primary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-primary.dropdown-toggle:focus {
            box-shadow: none;
        }

        .btn-primary:not(:disabled):not(.disabled):active,
        .btn-primary:not(:disabled):not(.disabled).active,
        .show > .btn-primary.dropdown-toggle {
            color: #fff;
            background-color: $black;
            border-color: $black;
        }
    }
}

.modal-portfolio-page {
    padding: 5.75rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    .a-image {
        height: auto !important;
        max-width: 11.5rem;
    }
    .left-side {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;
    }
    .link {
        font-size: 0.875rem;
        font-weight: bold;
        color: $black;
        font-size: 0.875rem;
    }
    .a-paragraph {
        font-weight: bold;
        font-size: 0.875rem;
    }
    .button-icon {
        color: map-get($theme-colors, 'primary');
    }
}

/*------------------------------------*\
    #Stats Section
\*------------------------------------*/

.o-stats-section {
    h4 {
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
    i {
        font-size: 2.5rem !important;
    }
    ul {
        list-style: none;
        padding: 0;
        font-size: 1rem;
    }
}

/*------------------------------------*\
    #Masonry Layout
\*------------------------------------*/

.masonry-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    @media (min-width: $desktop-xl) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 425px) {
        grid-row-gap: 1rem;
    }
    @media (min-width: 426px) {
        grid-gap: 1rem;
    }
    grid-auto-flow: dense;
    > div:nth-child(2n) {
        grid-column: span 2;
    }
    > div:nth-child(3n) {
        grid-column: span 1;
    }
    > div:nth-child(4n) {
        grid-column: span 1;
    }
}

.col-centered {
    padding-left: 25%;
    padding-right: 25%;
    @media (max-width: $tablet) {
        padding-left: unset;
        padding-right: unset;
    }
}
